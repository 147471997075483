<template>
    <div class="error">
        <button @click="handleError">返回首页</button>
    </div>
</template>

<script setup>
    const props = defineProps({
        error: Object
    })
    const handleError = () => clearError({ redirect: '/' })
</script>

<style scoped></style>